/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.billing-payment-card, .billing-usage-line, .billing-invoice {
    height: 32px;
    background-position: left;
    background-size: 32px;
    background-repeat: no-repeat;
    line-height: 32px;
    padding: 20px 0;
    border-bottom: 1px solid rgb(240,240,240);
    position: relative;
}

.billing-payment-card {
    padding-left: 60px;
    width: calc(100% - 60px);
}

.billing-payment-card-header, .billing-usage-line-header, .billing-invoice-header {
    clear: both;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-bottom: 1px solid rgb(240,240,240);
    margin-top: 30px;

}

.billing-payment-card *, .billing-payment-card-header *, .billing-usage-line *, .billing-usage-line-header *, .billing-invoice *, .billing-invoice-header * {
    float: left;
}

.billing-payment-card-title, .billing-usage-line-title, .billing-invoice-title {
    width: calc(100% - 360px);
    text-align: left;
    line-height: 48px;
}

.billing-payment-card-desc, .billing-usage-line-desc, .billing-invoice-number {
    width: calc(100% - 360px);
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.billing-payment-card-title, .billing-payment-card-desc {
    width: calc(100% - 340px);
}

.billing-payment-card-last4, .billing-payment-card-expiry, .billing-usage-line-unit, .billing-usage-line-quantity, .billing-usage-line-total, .billing-invoice-date, .billing-invoice-amount, .billing-invoice-status {
    width: 120px;
    text-align: right;
    color: rgb(160,160,160);
}

.billing-payment-card-last4, .billing-payment-card-expiry {
    width: 100px;
}

.billing-payment-card-active {
    width: 110px;
    text-align: right;
}

.billing-payment-card-actions {
    width: 30px;
}

.billing-payment-card-active-default {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    width: fit-content;
    float: right;
    padding: 0 10px;
    margin-top: 6px;
    color: white;
    border-radius: 4px;
    background-color: var(--color-green);
}

.billing-add-card-button {
    float: left;
    margin-top: 0 !important;
    padding-left: 40px !important;
    background-image: url('/images/icons/invite.png');
}

.billing-payment-card-header *, .billing-usage-line-header *, .billing-invoice-header * {
    font-weight: 700 !important;
    color: black !important;
}

.billing-payment-card-actions-button {
    position: absolute;
    right: 0px;
    top: 28px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.billing-add-card-line {
    clear: both;
}

.billing-add-card-number {
    float: left;
    width: 380px;
}

.billing-add-card-cvc {
    float: left;
    width: 90px;
    margin-left: 30px;
}

.billing-add-card-exp-mm {
    float: left;
    width: 110px;
}

.billing-add-card-exp-yyyy {
    float: left;
    width: 90px;
    margin-left: 10px;
}

.billing-add-card-zip {
    float: left;
    width: 80px;
    margin-left: 30px;
}

.billing-add-card-country {
    float: left;
    width: 150px;
    margin-left: 30px;
}

.billing-input-logo {
    position: absolute;
    right: 0;
    top: 6px;
}

.billing-input-logo img {
    width: 32px;
    height: 32px;
}