/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.editable {
    position: relative;
    height: 40px;
    line-height: 40px;
    max-width: 360px;
    margin-right: 20px;
    font-size: 18px;
}

.editable input {
    width: calc(100% - 40px) !important;
    height: calc(100% - 2px) !important;
    line-height: inherit !important;
    margin: 0 !important;
    font-weight: 400 !important;
    padding-right: 30px !important;
}

.editable-value {
    float: left;
    height: inherit !important;
    line-height: inherit !important;
    padding: 0 10px;
}

.editable-button-save {
    position: absolute;
    right: 8px;
    top: 12px;
    cursor: pointer;
}

.editable-button-edit {
    float: left;
    margin-top: 10px;
    margin-left: 4px;
    cursor: pointer;
}