/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.menubar {
    position: fixed;
    top: 0;
    height: 80px;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: white;
    box-shadow: 0 0 20px -5px rgba(0,0,0,0.2);
}

.menubar-logo {
    position: fixed;
    left: 30px;
    top: 16px;
    height: 48px;
    width: 192px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}

.menubar-title {
    position: fixed;
    left: 72px;
    top: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgb(40,40,40);
    user-select: none;
    cursor: pointer;
}

.menubar-links {
    position: fixed;
    left: 340px;
    top: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.menubar-links div {
    float: left;
    margin-right: 40px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}

.menubar-links div:hover {
    color: var(--color-accent);
}

.menubar-memberships-active {
    position: fixed;
    text-align: right;
    right: 100px;
    top: 20px;
    height: 40px;
    line-height: 40px;
    width: 200px;
    font-size: 18px;
    font-weight: 500;
    padding-right: 30px;
    background-image: url('/images/icons/down.png');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: right;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menubar-memberships-active:hover {
    color: var(--color-accent);
}

.menubar-memberships-active-open {
    background-image: url('/images/icons/up.png');
}

.menubar-memberships-list {
    position: fixed;
    z-index: 3;
    right: 92px;
    top: 12px;
    width: 258px;
    /* height: 120px; */
    padding: 8px 0;
    background-color: white;
    box-shadow: 2px 2px 20px -5px rgba(0,0,0,0.3);
    border-radius: 8px;
    font-size: 18px;
    text-align: left;
    font-weight: 400;
    transition: all 0.1s ease-in-out;
    opacity: 1;
    transform-origin: top;
    user-select: none;
}

.menubar-memberships-list-closed {
    pointer-events: none;
    opacity: 0;
}

.menubar-memberships-option {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menubar-memberships-option:hover {
    background-color: rgb(240,240,240);
}


.menubar-memberships-option-selected {
    background-color: rgb(240,240,240);
}

@media only screen and (max-width: 700px) {
    .menubar-memberships {
        display: none;
    }
}