/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.container {
    position: fixed;
    left: 320px;
    right: 0;
    top: 80px;
    bottom: 0;
    transition: all 0.2s ease-in-out;
}

.scrollable {
    padding: 20px;
    padding-bottom: 80px;
}

.container h1 {
    margin-top: 52px;
    margin-bottom: 16px;
}

.container .subtitle {
    font-size: 16px;
    font-weight: 400;
}

.container h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px !important;
}

.container hr {
    margin: 40px 0;
    border: none !important;
    border-bottom: 1px solid rgb(240,240,240) !important;
}

.container p {
    margin-top: 20px;
    line-height: 26px;
}

.container .hint {
    padding-left: 0 !important;
    margin-bottom: 24px;
}

.manage-delete-button {
    float: left;
    margin-top: 56px !important;
    padding-left: 40px !important;
    background-image: url('/images/icons/delete-inv.png');
}

@media only screen and (max-width: 1200px) {
    .container {
        left: 100px;
    }
}

@media only screen and (max-width: 700px) {
    .container {
        left: 0;
    }
}