/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.profile-avatar-container {
    position: relative;
    height: 128px;
    width: 128px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-avatar {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
}

.profile-avatar-empty {
    z-index: 0;
    background-color: rgb(245,245,245);
    background-image: url('/images/icons/avatar-empty.png');
}

.profile-avatar-processing {
    background-size: 80%;
    background-image: url('/images/loader.gif');
}

.profile-avatar-upload {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    padding-top: 75px;
    background-image: url('/images/icons/upload.png');
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: 50% 40%;
    background-color: var(--color-accent);
    font-weight: 600px;
    letter-spacing: 0.5px;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    opacity: 0;
}

.profile-avatar-upload:hover {
    opacity: 0.7;
}

.profile-password {
    max-width: 360px;
    margin-right: 20px;
}

.profile-password input {
    width: calc(100% - 40px) !important;
    padding-right: 30px !important;
}

.profile-password-icon {
    margin-top: -30px;
    width: 16px;
    height: 16px;
    float: right;
    margin-right: 4px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 100%;
}

.profile-password-warning-icon {
    background-image: url('/images/icons/warning.png');
}

.profile-password-ok-icon {
    background-image: url('/images/icons/accept.png');
}

.profile-password-warning {
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    color: rgb(180,180,180);
}