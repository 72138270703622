/*
// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory
*/

.contextmenu-menu {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    background-color: white;
    /* border: 1px solid rgb(230,230,230); */
    box-shadow: 1px 4px 20px 0 rgba(0,0,0,0.15);
    border-radius: 8px;
    transition: opacity 0.1s ease-in-out;
    padding: 6px 0;
    z-index: 1005;
    max-height: 300px;
}

.contextmenu-menu-opened {
    opacity: 1;
    pointer-events: all;
}

.contextmenu-option {
    height: 36px;
    line-height: 36px;
    margin: 2px 0;
    padding-left: 20px;
    padding-right: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    transition: background-color 0.1s ease-in-out;
}

.contextmenu-option:hover {
    background-color: rgb(245,245,245);
}