/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.members-invite-button {
    float: left;
    margin-top: 0 !important;
    padding-left: 40px !important;
    background-image: url('/images/icons/invite.png');
}

.members-filter {
    float: right;
    width: 240px !important;
    margin-top: 2px !important;
    padding-right: 30px !important;
    background-image: url('/images/icons/filter.png') !important;
    background-position: calc(100% - 8px) 50% !important;
    background-size: 16px !important;
    background-repeat: no-repeat !important;
}

.members-list {
    width: 100%;
    border-collapse: collapse;
}

.members-list thead {
    height: 48px;
    line-height: 48px;
    text-align: center;
}

.members-list th {
    border-bottom: 1px solid rgb(240,240,240);
}

.members-list th:first-child {
    text-align: left;
    padding-left: 64px;
}

.members-list th:last-child {
    text-align: right;
    padding-right: 8px;
    width: 30px;
}

.members-list tbody td {
    text-align: center;
    position: relative;
    height: 84px;
}

.members-list tbody td:first-child {
    text-align: left;
    min-width: 320px;
}

.members-list tbody td:last-child {
    text-align: right;
    width: 30px;
}

.members-line-avatar {
    position: absolute;
    top: 32px;
    left: 0;
}

.members-line-name {
    position: absolute;
    left: 64px;
    top: 38px;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    font-weight: 500;
}

.members-line-name-pending {
    color: rgb(200,200,200);
    font-weight: 400;
}

.members-line-email {
    position: absolute;
    left: 64px;
    top: 60px;
    height: 16px;
    line-height: 16px;
    font-size: 15px;
    font-weight: 400;
    color: rgb(200,200,200);
}

.members-line-access {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    width: fit-content;
    padding: 0 10px;
    margin: 0 auto;
    margin-top: 28px;
    color: white;
    border-radius: 4px;
}

.members-line-access-member {
    color: rgb(200,200,200);
}

.members-line-access-admin {
    background-color: var(--color-green);
}

.members-line-access-owner {
    background-color: var(--color-blue);
}

.members-line-meta {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    margin-top: 28px;
}

.members-line-actions {
    position: absolute;
    right: 0px;
    top: 47px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .members-filter {
        display: none;
    }

    .members-list thead th:not(:first-child):not(:last-child) {
        display: none;
    }

    .members-list tbody td:not(:first-child):not(:last-child) {
        display: none;
    }
}