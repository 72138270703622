/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.85);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 4000;
    animation: fadein 0.15s ease-in-out 0s 1 !important;
}

@supports not (backdrop-filter: blur(10px)) {
    .modal-container {
        background-color: rgba(255,255,255,0.95);
    }
}

.modal-close {
    position: absolute;
    z-index: 4001;
    left: 32px;
    top: 32px;
    width: 16px;
    height: 16px;
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.modal-close:hover {
    opacity: 0.9;
}

.modal-back {
    position: absolute;
    z-index: 4001;
    left: 32px;
    top: 32px;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.modal-back:hover {
    color: var(--color-accent);
}

.modal-content {
    position: relative;
    padding-bottom: 40px;
}

.modal-center {
    width: 800px;
    margin-left: calc(50% - 400px);
    padding-top: 15vh;
    padding-bottom: 5vh;
    text-align: center;
}

.modal-form {
    width: 500px !important;
    margin: 0 auto;
}

.modal-caution-message {
    width: 500px;
    margin: 0 auto;
    font-size: 18px !important;
    line-height: 32px !important;
}

.modal-caution-buttons {
    width: 420px;
    margin: 0 auto;
    margin-top: 60px;
}

.modal-caution-decline {
    float: left;
    padding-top: 2px;
    font-size: 18px;
    line-height: 40px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.modal-caution-decline:hover {
    color: var(--color-accent);
}

.modal-caution-confirm {
    float: right;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    width: 160px;
    text-align: center;
    color: white;
    font-weight: 500;
    letter-spacing: 0.2px;
    background-color: rgb(50,50,50);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.modal-caution-confirm:hover {
    /* background-color: rgb(20,20,20); */
    background-color: var(--color-accent);
}

.modal-content h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    margin: 20px 0;
    color: rgb(200,200,200);
}

.modal-content p {
    font-size: 18px;
    line-height: 30px;
}

.modal-form {
    width: 500px;
    margin: 0 auto;
    margin-top: 60px;
}

.modal-form label {
    text-align: left;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 5px;
}

.modal-form textarea {
    margin-top: 5px;
    width: calc(100% - 20px);
    min-width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    min-height: 100px;
    padding: 10px;
    border: none;
    background-color: rgba(127,127,127,0.08);
    border-radius: 6px;
    font-size: 16px;
    line-height: 22px;
}

