/*
// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory
*/

.user-icon {
    position: fixed;
    right: 18px;
    top: 16px;
    width: 48px;
    height: 48px;
    z-index: 9999;
    cursor: pointer;
}

.user-menu-arrow {
    position: fixed;
    z-index: 9999;
    right: 14px;
    top: -10px;
    width: 20px;
    height: 10px;
    transition: all 0.1s ease-in-out;
}

.user-menu {
    position: fixed;
    z-index: 9998;
    right: 18px;
    top: 75px;
    width: 220px;
    height: 200px;
    padding: 20px;
    padding-bottom: 0;
    background-color: white;
    box-shadow: 2px 2px 20px -5px rgba(0,0,0,0.3);
    border-radius: 8px;
    font-size: 15px;
    text-align: left;
    font-weight: 400;
    transition: all 0.1s ease-in-out;
    opacity: 1;
    transform: scale(1.0);
    transform-origin: top;
    max-height: calc(100vh - 145px);
    /* overflow-y: auto; */
}

.user-menu-closed {
    pointer-events: none;
    opacity: 0;
    /* transform: scaleY(0.5); */
}

.user-menu h1 {
    margin: 0;
    margin-bottom: -10px;
    font-size: 18px;
    line-height: 40px;
    font-weight: 500;
    color: var(--color-text);
    border-bottom: 1px solid var(--color-border);
}

.user-menu h2 {
    font-size: 12px;
    color: rgb(160,160,160);
    font-weight: 400;
    margin-top: 18px;
    line-height: 26px;
    text-transform: uppercase;
}

.user-menu h3 {
    font-size: 14px;
    color: rgb(100,100,100);
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: -16px;
    line-height: 26px;
    text-align: right;
    /* text-transform: uppercase; */
}

.user-menu a, .user-menu .a {
    display: block;
    padding-left: 6px;
    font-size: 14px;
    line-height: 26px;
}

.user-menu a:hover, .user-menu .a:hover {
    text-decoration: underline;
}