/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.login-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgb(40,40,40);
}

.login-background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
}

.login-box {
    position: relative;
    width: calc(100vw - 94px);
    max-width: 320px;
    height: 350px;
    margin: 0 auto;
    overflow: hidden;
    padding: 36px;
    background-color: white;
    box-shadow: 0 0 50px -5px rgba(0,0,0,0.3);
    animation: pulse-box-shadow 3s infinite;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}

@keyframes pulse-box-shadow {
    0% {
        box-shadow: 0 0 50px -5px rgba(0,0,0,0.2);
    }

    40% {
        box-shadow: 0 0 60px -5px rgba(0,0,0,0.4);
    }

    100% {
        box-shadow: 0 0 50px -5px rgba(0,0,0,0.2);
    }
}

.login-box h1 {
    font-size: 24px;
    height: 48px;
    line-height: 48px;
    padding-bottom: 5px;
    margin: 0 -10px 20px -10px;
    text-align: center;
    font-weight: 500;
    border-bottom: 1px solid rgb(240,240,240);
}

.login-box-h1-below-back {
    margin-top: 10px !important;
}

.login-box h2 {
    font-size: 18px;
    color: rgb(200,200,200);
    line-height: 18px;
    margin: 16px 0 40px 0;
}

.login-box p {
    font-size: 15px;
    line-height: 22px;
    color: rgb(140,140,140);
    margin-bottom: 25px;
}

.login-box p a {
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    color: var(--color-primary);
}

.login-box p a:hover {
    text-decoration: underline;
    color: var(--color-accent);
}

.login-account-container {
    height: 48px;
    padding: 11px 10px;
    margin: 5px -10px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
}

.login-account-container:hover {
    background-color: rgb(245,245,245);
}

.login-account-name {
    position: absolute;
    top: 14px;
    left: 70px;
    right: 0;
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.login-account-email {
    position: absolute;
    top: 36px;
    left: 70px;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    right: 0;
    color: rgb(180,180,180);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.login-account-new, .login-account-add {
    padding-left: 70px;
    background-position: 10px 11px;
    background-repeat: no-repeat;
    background-size: 48px;
    line-height: 47px;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.login-account-new {
    background-image: url('/images/icons/plus.png');
}

.login-account-add {
    background-image: url('/images/icons/user.png');
}

.login-button {
    font-size: 15px;
    color: var(--color-blue);
    font-weight: 500;
    color: var(--color-blue);
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}

.login-button:hover {
    color: var(--color-red);
}

.login-password-request-otp {
    height: 20px;
    line-height: 20px;
    text-align: left;
    margin: 20px 0;
}

.login-forgot-password {
    position: absolute;
    right: 0;
    top: 70px;
    font-size: 14px;
    color: rgb(160,160,160);
    font-weight: 400;
}

.login-back-button {
    position: absolute;
    left: 21px;
    top: 28px;
}

.login-loading {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    pointer-events: none;
    z-index: 2000;
    transition: opacity 0.15s ease-in-out;
    background-image: url('/images/loader.gif');
    background-position: center;
    background-repeat: no-repeat;
}

.login-team-container {
    height: 36px;
    padding: 10px 10px;
    margin: 5px 0;
    width: calc(100% - 40px);
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.login-team-container:hover {
    background-color: rgb(245,245,245);
}

.login-team-container-confirm {
    background-color: transparent !important;
    cursor: auto !important;
    margin-bottom: -10px !important;
    padding: 10px 0 !important;
}

.login-payment-container {
    height: 36px;
    padding: 10px 10px;
    padding-left: 60px;
    margin: 5px -10px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    font-size: 18px;
    line-height: 36px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-position: 10px;
    background-repeat: no-repeat;
    background-size: 40px;
}

.login-payment-add-card {
    height: 36px;
    padding: 10px 10px;
    padding-left: 60px;
    margin: 5px -10px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    font-size: 18px;
    line-height: 36px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-position: 14px;
    background-repeat: no-repeat;
    background-size: 32px;
    background-image: url('/images/icons/plus.png');
}

.login-payment-container:hover, .login-payment-add-card:hover {
    background-color: rgb(245,245,245);
}

.login-teams-scrollbox {
    height: 188px;
    overflow: hidden;
    margin: 0 -10px;
}

.login-team-access-level {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: rgb(160,160,160);
}

.login-invite p {
    color: rgb(80,80,80);
}

.password-alert {
    color: var(--color-red);
    font-size: 14px;
    padding-top: 10px;
}

.password-strong {
    background-image: url('/images/icons/accept.png');
    background-size: 16px;
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 20px;
    color: var(--color-green);
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: -10px;
}

.password-show-icon {
    background-image: url('/images/icons/hidden.png');
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 8px;
}

.password-show-icon:hover {
    opacity: 0.6;
}

.password-show-icon-hidden {
    background-image: url('/images/icons/visible.png');
}

.login-form {
    width: 320px !important;
    margin: 0 auto;
}

.payment-add-card-line {
    clear: both;
}

.payment-add-card-number {
    float: left;
    width: 240px;
}

.payment-add-card-cvc {
    float: left;
    width: 60px;
    margin-left: 20px;
}

.payment-add-card-exp-mm {
    float: left;
    width: 60px;
}

.payment-add-card-exp-yyyy {
    float: left;
    width: 70px;
    margin-left: 10px;
}

.payment-add-card-zip {
    float: left;
    width: 75px;
    margin-left: 20px;
}

.payment-add-card-country {
    float: left;
    width: 65px;
    margin-left: 20px;
}

.payment-input-logo {
    position: absolute;
    right: 0;
    top: 6px;
}

.payment-input-logo img {
    width: 32px;
    height: 32px;
}