/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.sidebar {
    position: fixed;
    z-index: 2;
    left: 0;
    width: 290px;
    padding-left: 30px;
    padding-top: 80px;
    top: 80px;
    bottom: 0;
    transition: all 0.2s ease-in-out;
    /* border-right: 1px solid rgb(240,240,240); */
}

.sidebar h1 {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(140,140,140);
    margin-bottom: 30px !important;
}

.sidebar a, .sidebar-a {
    display: block;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 24px;
    padding-left: 36px;
    margin-left: 12px;
    height: 32px;
    line-height: 32px;
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    color: black !important;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sidebar a:hover, .sidebar-a:hover {
    color: var(--color-accent) !important;
}

@media only screen and (max-width: 1200px) {
    .sidebar {
        width: 40px;
        padding-left: 10px;
        padding-right: 18px;
        padding-top: 56px;
        background-color: rgb(245,245,245);
    }

    .sidebar:hover {
        width: 200px;
        box-shadow: 0 0 20px -5px rgba(0,0,0,0.2);
    }

    .sidebar h1 {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .sidebar {
        display: none;
    }
}