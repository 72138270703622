/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

* {
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif !important;
    --color-red: #e74c3c;
    --color-green: #00b770;
    --color-blue: #3db0f8;
}

a {
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    color: var(--color-blue);
    cursor: pointer;
}

a:hover {
    color: var(--color-accent);
}

h1 {
    font-weight: 500;
    margin-bottom: 40px;
}

h2 {
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 40px;
}

.loader {
    background-image: url('/images/loader.gif');
    background-position: 50% calc(50% - 30px);
    background-repeat: no-repeat;
    background-size: 128px;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    text-align: center;
    padding-top: calc(50vh + 40px);
    font-size: 18px;
    opacity: 0;
    visibility: hidden;
}

.credits {
    position: absolute;
    bottom: 12px;
    left: calc(50% - 60px);
    font-size: 12px;
    font-weight: 300;
    padding-right: 18px;
    background-image: url('/images/logo.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: 16px;
    color: rgb(180,180,180);
}

.credits-company {
    display: inline-block !important;
    padding-left: 0 !important;
    font-size: inherit !important;
    line-height: inherit !important;
    color: rgb(120,120,120);
    text-decoration: none;
    font-weight: 600;
}

.avatar {
    position: absolute;
    width: 45px;
    height: 46px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    border: 1px solid transparent;
    border-radius: 50%;
    overflow: hidden;
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 47px;
    text-align: center;
    padding-right: 1px;
    text-transform: uppercase;
}

.avatar img {
    position: absolute;
    width: 46px;
    height: 46px;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}

.hint {
    text-align: left;
    color: rgb(160,160,160);
    font-size: 15px;
    line-height: 22px;
    margin-top: 4px;
    padding-left: 10px;
}

.palette-color-0 { background-color: #00a4f6; }
.palette-color-1 { background-color: #ff4352; }
.palette-color-2 { background-color: #00b770; }
.palette-color-3 { background-color: #506f79; }
.palette-color-4 { background-color: #3050f4; }
.palette-color-5 { background-color: #ff6141; }
.palette-color-6 { background-color: #8d43f2; }
.palette-color-7 { background-color: #ffd04d; }

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.input-container {
    position: relative;
}