/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

label {
    display: block;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
}

input[type=text], input[type=password] {
    width: calc(100% - 20px);
    padding: 0 10px;
    border: none;
    /* border-bottom: 2px solid rgb(40,40,40); */
    border-bottom: 2px solid var(--color-primary);
    background: none;
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 6px;
    transition: border 0.2s ease-in-out;
}

input[type=text]:hover, input[type=password]:hover, input[type=text]:focus, input[type=password]:focus {
    border-bottom: 2px solid var(--color-accent);
    outline: none;
    box-shadow: none;
}

input[type=text]::placeholder, input[type=password]::placeholder {
    color: rgb(200,200,200);
    font-weight: 400;
}

input[type=submit], .button {
    height: 40px;
    border: none;
    outline: none;
    /* background-color: rgb(40,40,40); */
    background-color: var(--color-primary);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 16px 50%;
    color: white;
    font-size: 18px;
    line-height: 40px;
    font-weight: 500;
    padding: 0 20px;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

input[type=submit]:hover, input[type=submit]:focus, .button:hover {
    /* background-color: rgb(20,20,20); */
    background-color: var(--color-accent);
    outline: none;
    box-shadow: none;
}

.button {
    width: fit-content;
    /* margin: 0 auto;
    margin-top: 24px; */
}

.required {
    color: rgb(200,0,0);
}

textarea:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.select-container {

}

.select__control {
    padding-top: 4px !important;
    border: none !important;
    border-bottom: 2px solid black !important;
    border-radius: 0 !important;
    transition: border 0.2s ease-in-out !important;
    outline: none !important;
    box-shadow: none !important;
}

.select__control:hover {
    border-bottom: 2px solid var(--color-accent) !important;
}

.select__value-container {
    text-align: left !important;
    font-weight: 600 !important;
}

.select__single-value {
    text-align: left !important;
}

.select__input-container, .select__input {
    height: 30px !important;
    text-align: left !important;
}

.select__indicator-separator {
    display: none;
    width: 0;
}

.select__indicator {
    padding: 0 !important;
}

.select__menu {
    background-color: white !important;
    box-shadow: 1px 4px 20px 0 rgba(0,0,0,0.15) !important;
    border-radius: 8px !important;
    padding: 6px 0 !important;
}

.select__option {
    text-align: left !important;
}

.select__option:hover {
    background-color: rgb(235,235,235) !important;
}

.select__placeholder {
    color: rgb(220, 220, 220) !important;
    font-weight: 400 !important;
}